import { Button, Col, Row, Table } from "react-bootstrap-v5";
import { getRequestOptions, postRequestOptions } from "../helpers/Fetchwrapper";
import { useEffect, useState } from "react";
import Select from "react-select";
import showLoader from "../helpers/ShowLoader";
import Swal from "sweetalert2";
import React from 'react';
import {numberFormat } from '../helpers/GlobalHelper';  

interface LedgerData {
    nonCurrentAssetAmount: number;
    investmentPropertyAmount: number;
    interBuCurrentAccountAmount: number;
    materialsAmount: number;
    finishedGoodsAmount: number;
    inTransitAmount: number;
    accountsReceivableAmount: number;
    intercompanyReceivables: number;
    advancesAmount: number;
    vatCurrentAccountAmount: number;
    cashBankAmount: number;
    shareCapitalAmount: number;
    shareMoneyDeposit: number;
    retainedEarningsAmount: number;
    tradePayableAmount: number;
    ibuInterestPayableAmount: number;
    otherPayableAmount: number;
    shortTermLoanAmount: number;
    bankOverdraftAmount: number;
    incomeTax: number;
    totalNonCurrentAsset: number;
    totalCurrentAsset: number;
    totalAsset: number;
    totalEquity: number;
    totalLiabilities: number;
    totalEquityLiabilities: number;
}

export default function BalanceSheet() {

    // Helper function to format a Date object as yyyy-MM-dd
    const formatDate = (date: Date) => {
        // return date.toISOString().split("T")[0];
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero
        const day = date.getDate().toString().padStart(2, "0"); // Add leading zero
        return `${year}-${month}-${day}`;
    };

    // Get the current date
    const today = new Date();

    // Determine the fiscal start and end dates
    const fiscalYearStart = today.getMonth() < 6 // Before July
    ? new Date(today.getFullYear() - 1, 6, 1) // Previous year July 1st
    : new Date(today.getFullYear(), 6, 1); // Current year July 1st

    const fiscalYearEnd = new Date(fiscalYearStart.getFullYear() + 1, 5, 30); // Next year June 30th

    // Default start and end dates formatted as yyyy-MM-dd
    const defaultStartDate = formatDate(fiscalYearStart);
    const defaultEndDate = formatDate(fiscalYearEnd);

    const [balanceSheetData, setBalanceSheet] = useState<LedgerData>({} as LedgerData);
    const [selectProjectOptions, setSelectProjectOptions] = useState([])
    const [selectedProject, setSelectedProject] = useState<{ value: string; label: string; } | null>(null);
    const [startDate, setStartDate] = useState<string | null>(defaultStartDate);
    const [endDate, setEndDate] = useState<string | null>(defaultEndDate);

    useEffect(() => {
        // Check if selectProjectOptions is available before proceeding
        if (selectProjectOptions.length === 0) return;
        setSelectedProject(selectProjectOptions[0]);

    }, [selectProjectOptions]);

    useEffect(() => {
        geBalanceSheet();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject, startDate, endDate]);
    
    async function getProjectList() {
        const url = process.env.REACT_APP_API_URL + 'projects';

        const response = await fetch(url, getRequestOptions());

        const data = await response.json();

        data.data.map((element: any) => {
            element.value = element.id_projects;
            element.label = element.project_name;
            return element;
        })
        setSelectProjectOptions(data.data);
        setSelectedProject(selectProjectOptions[0]);
    }

    const handleProjectChange = (project: any) => {
        setSelectedProject(project);
    };

    async function geBalanceSheet() {
        showLoader();
        const response = await fetch(process.env.REACT_APP_API_URL + 'reports/balance-sheet', postRequestOptions(
            {
                idProjects: selectedProject?.value,
                startDate: startDate,
                endDate: endDate
            }
        ))
        const data = await response.json();
        if (data.success) {
            Swal.close();
            setBalanceSheet(data.data);
        } else {
            Swal.close();
            // toast(data.errors, { type: 'error' });
        }
    }

    useEffect(() => {
        geBalanceSheet();
        getProjectList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const queryParams = new URLSearchParams([
        ['idProjects', selectedProject?.value || ''],
        ['startDate', startDate || ''],
        ['endDate', endDate || '']
    ]);
    

    return <div className='card card-custom'>
        <div className='card-header'>
            <div className="card-title">
                <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                    Balance Sheet
                </span>
            </div>
            <div className='card-toolbar'>
                <div className='d-flex justify-content-end align-items-center'>
                    <Select
                        value={selectedProject}
                        className="form-control-sm h-50px w-250px me-3"
                        defaultValue={selectProjectOptions}
                        onChange={handleProjectChange}
                        options={selectProjectOptions}
                        placeholder="Select project"
                    />
                    <Row>
                        <Col className="d-flex align-items-center text-end pe-0">
                            From:
                        </Col>
                        <Col>
                            <input onChange={e => { setStartDate(e.target.value) }} value={startDate || ""} type="date" className="d-inline form-control form-control-sm" />
                        </Col>
                        <Col className="d-flex align-items-center text-end pe-0">
                            To:
                        </Col>
                        <Col>
                            <input onChange={e => { setEndDate(e.target.value) }} value={endDate || ""} type="date" className="d-inline form-control form-control-sm" />
                        </Col>
                    </Row>
                    <Button onClick={() => geBalanceSheet()} variant="primary" className="ms-2 p-2 me-2" size="sm">Refresh</Button>
                    <a className="btn btn-sm btn-primary p-2 ml-2" href={`${process.env.REACT_APP_BACKEND}balance_sheet_download?` + queryParams.toString()}><i className='fas fa-download'></i>Excel Download</a>
                    {/* <pre>{JSON.stringify(defaultStartDate, null, 2)}</pre>
                    <pre>{JSON.stringify(defaultEndDate, null, 2)}</pre>
                    <pre>{JSON.stringify(startDate, null, 2)}</pre> */}
                </div>
            </div>
        </div>
        <div className='card-body'>
            <div className="row">
                <div className="col">
                    <div className="trial-balance-container table-responsive">
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th className="p-2 border text-center" width="50%">Particulars</th>
                                    <th className="p-2 border text-center" width="50%">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="p-2 border"><strong>Non-current assets</strong></td>
                                    <td className="p-2 border text-end"><strong></strong></td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Property, plant & equipment</td>
                                    <td className="p-2 border text-end">{numberFormat(balanceSheetData.nonCurrentAssetAmount)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Investment property</td>
                                    <td className="p-2 border text-end">{numberFormat(balanceSheetData.investmentPropertyAmount)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border"><strong>Total non-current assets</strong></td>
                                    <td className="p-2 border text-end"><strong>{numberFormat(balanceSheetData.totalNonCurrentAsset)}</strong></td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Business Unit C/A</td>
                                    <td className="p-2 border text-end">{numberFormat(balanceSheetData.interBuCurrentAccountAmount)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border"><strong>Inventories</strong></td>
                                    <td className="p-2 border text-end"><strong>{ numberFormat(balanceSheetData.materialsAmount + balanceSheetData.finishedGoodsAmount + balanceSheetData.inTransitAmount)}</strong></td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Materials</td>
                                    <td className="p-2 border text-end">{numberFormat(balanceSheetData.materialsAmount)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Finished Goods</td>
                                    <td className="p-2 border text-end">{numberFormat(balanceSheetData.finishedGoodsAmount)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">In Transit</td>
                                    <td className="p-2 border text-end">{numberFormat(balanceSheetData.inTransitAmount)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Trade receivables</td>
                                    <td className="p-2 border text-end">{numberFormat(balanceSheetData.accountsReceivableAmount)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Intercompany Receivables</td>
                                    <td className="p-2 border text-end">{numberFormat(balanceSheetData.intercompanyReceivables)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Advances, deposits & pre-payments</td>
                                    <td className="p-2 border text-end">{numberFormat(balanceSheetData.advancesAmount)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">VAT current account</td>
                                    <td className="p-2 border text-end">{numberFormat(balanceSheetData.vatCurrentAccountAmount)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Cash & Cash Equivalents</td>
                                    <td className="p-2 border text-end">{numberFormat(balanceSheetData.cashBankAmount)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border"><strong>Total current assets</strong></td>
                                    <td className="p-2 border text-end"><strong>{numberFormat(balanceSheetData.totalCurrentAsset)}</strong></td>
                                </tr>
                                <tr>
                                    <td className="p-2 border"><strong>Total Assets</strong></td>
                                    <td className="p-2 border text-end"><strong>{numberFormat(balanceSheetData.totalAsset)}</strong></td>
                                </tr>
                               
                                <tr>
                                    <td className="p-2 border">Share capital</td>
                                    <td className="p-2 border text-end">{numberFormat(balanceSheetData.shareCapitalAmount)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Share Money Deposit</td>
                                    <td className="p-2 border text-end">{numberFormat(balanceSheetData.shareMoneyDeposit)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Retained earnings</td>
                                    <td className="p-2 border text-end">{numberFormat(balanceSheetData.retainedEarningsAmount)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border"><strong>Total Equity </strong></td>
                                    <td className="p-2 border text-end"><strong>{numberFormat(balanceSheetData.totalEquity)}</strong></td>
                                </tr>

                                <tr className="font-semibold bg-gray-100">
                                    <td className="p-2 border"><strong>Current liabilities</strong></td>
                                    <td className="p-2 border text-end"><strong></strong></td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Trade Payables</td>
                                    <td className="p-2 border text-end">{numberFormat(balanceSheetData.tradePayableAmount)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Intercompany Payables</td>
                                    <td className="p-2 border text-end">{numberFormat(balanceSheetData.ibuInterestPayableAmount)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Other Payables</td>
                                    <td className="p-2 border text-end">{numberFormat(balanceSheetData.otherPayableAmount)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Short-term loans from banks</td>
                                    <td className="p-2 border text-end">{numberFormat(balanceSheetData.shortTermLoanAmount)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Bank overdrafts</td>
                                    <td className="p-2 border text-end">{numberFormat(balanceSheetData.bankOverdraftAmount)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Provision for tax </td>
                                    <td className="p-2 border text-end">{numberFormat(balanceSheetData.incomeTax)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border"><strong>Total current liabilities</strong></td>
                                    <td className="p-2 border text-end"><strong>{numberFormat(balanceSheetData.totalLiabilities)}</strong></td>
                                </tr>
                                <tr>
                                    <td className="p-2 border"><strong>Total equity and liabilities</strong></td>
                                    <td className="p-2 border text-end"><strong>{numberFormat(balanceSheetData.totalEquityLiabilities)}</strong></td>
                                </tr>
                                
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            
        </div>
    </div >
}