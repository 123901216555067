import React, { useState, useEffect } from 'react';
import { Table, Form } from 'react-bootstrap';
import Select from 'react-select';
import { postRequestOptions, getRequestOptions } from '../helpers/Fetchwrapper';
import { Card } from 'react-bootstrap-v5';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { numberFormat } from '../helpers/GlobalHelper';

// Define a type for the company data
interface CompanyData {
    idCompanies: number;
    idAccLedgers: number;
    companyName: string;
    opBalance: number;
    totalActualInvoicedAmount: number;
    totalActualPaymentAmount: number;
    totalSalesOrder: number;
    totalShipmentAmount: number;
    totalInvoiceQuantity: number;
    totalInvoicedAmount: number;
    totalInvoicedOtherAmount: number;
    totalCollectionAmount: number;
    totalCollectionOtherAmount: number;
    totalBalance: number;
    salesRepresentatives: string;
}

const SalesReport: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [salesData, setSalesData] = useState<CompanyData[]>([]);
    const [formData, setFormData] = useState({
        idProjects: '',
        idSrCategories: '',
        fromDate: '',
        toDate: '',
    });

    const [projects, setProjects] = useState<{ label: string; value: string }[]>([]);
    const [project, setProject] = useState<{ label: string; value: string }>({ label: 'Select Project', value: '' });

    
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState({ label: 'Select SR Category', value: '' });

    useEffect(() => {
        getProjects();
    }, []);

    useEffect(() => {
        if (projects.length > 0) {
            setProject(projects[0]);
            setFormData({
                ...formData,
                idProjects: projects[0].value,
            });

            getCategories(projects[0].value)
        }
    }, [projects]); // eslint-disable-line react-hooks/exhaustive-deps

    const getProjects = () => {
        fetch(process.env.REACT_APP_API_URL + 'lead/projects', getRequestOptions())
            .then((resp) => resp.json())
            .then((resp) => {
                let projectObj = resp.data.map((item: any) => ({
                    label: item.name,
                    value: item.id,
                }));
                setProjects(projectObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop');
            });
    };

    const handleProjectChange = (project: any) => {
        setProject(project);
        setFormData({ ...formData, idProjects: project ? project.value : '' });
        getCategories(project.value)
    };


    const getCategories = async (idProjects: number) => {
        
        const url = process.env.REACT_APP_API_URL + 'allSrCategory?idProjects=' + idProjects
        const response = await fetch(url, getRequestOptions())
        const data = await response.json()

        data.map((element: any) => {
            element.value = element.id_sr_categories;
            element.label = element.sr_category_name;
            return element;
        })

        var allCategoriesOpt = [{ id: '', name: '', label: 'All', value: '' }]
        let allCategories = data.concat(allCategoriesOpt);
        setCategories(allCategories);

    }

    const handleCategoryChange = (category: any) => {
        setCategory(category);
        if(category){
            setFormData({ ...formData, idSrCategories: category.value });
        }else{
            setFormData({ ...formData, idSrCategories: '' });
        }
    }
    
    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleViewReport = async () => {
        setLoading(true);
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL + 'reports/sales',
                postRequestOptions(formData)
            );
            const data = await response.json();
            if (data.success) {
                setSalesData(data.data);
            } else {
                setSalesData([]);
                data.errors.forEach((error: string) => toast.error(error, { position: 'bottom-right', autoClose: 4000 }));
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        } finally {
            setLoading(false);
        }
    };

    const queryParams = new URLSearchParams(formData);

    return (
        <>
            <Card className='mb-4'>
                <Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Text>
                        <div className='row'>
                            <div className='col-md-3'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>Project<span className='required'></span></Form.Label>
                                    <Select
                                        value={project}
                                        onChange={handleProjectChange}
                                        options={projects}
                                        menuPosition="fixed"
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>Select SR Category</Form.Label>
                                    <Select
                                        // defaultValue={selectProjectOptions}
                                        onChange={handleCategoryChange}
                                        options={categories}
                                        placeholder={category.label}
                                        menuPosition="fixed"
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>From Date<span className='required'></span></Form.Label>
                                    <Form.Control type="date" placeholder="From Date" name='fromDate' onChange={handleChange}/>
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>To Date<span className='required'></span></Form.Label>
                                    <Form.Control type="date" placeholder="To Date" name='toDate' onChange={handleChange}/>
                                </Form.Group>
                            </div>
                            <div className='col-md-1'>
                                <div className="form-control-sm ps-0 mt-7">
                                    <button className='btn btn-sm btn-primary w-100 p-3' onClick={handleViewReport} disabled={loading}>
                                        {loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>View Report</span>
                                    </button>
                                </div>                                
                            </div>
                            <div className='col-md-1'>
                                <div className="form-control-sm ps-0 mt-7">
                                    <a className="btn btn-sm btn-primary w-100 p-3 mb-2" href={`${process.env.REACT_APP_BACKEND}sales_report_download?` + queryParams.toString()}><i className='fas fa-download'></i> Download</a>
                                </div>                                
                            </div>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>

            <div className="row">
                <div className="col-12">
                    <Table responsive bordered hover>
                        <thead>
                            <tr>
                                <th className="text-center">Sl.</th>
                                <th className="text-center">Client Name</th>
                                {/* <th className="text-center">Op. Balance</th> */}
                                <th className="text-center">Order</th>
                                <th className="text-center">Invoiced</th>
                                <th className="text-center">Payment Received</th>
                                <th className="text-center">Other Dr Amount</th>
                                <th className="text-center">Other Cr Amount</th>
                                <th className="text-center">Balance</th>
                                <th className="text-center">Shipment Amount</th>
                                <th className="text-center">Sales Person</th>
                            </tr>
                        </thead>
                        <tbody>
                            {salesData.length > 0 ? (
                                salesData.map((company, index) => (
                                    <tr key={company.idCompanies}>
                                        <td className="text-center">{index + 1}</td>
                                        <td>
                                            <Link
                                                to={`reports?type=ledgerStatement&idProjects=${formData.idProjects}&idAccLedgers=${company.idAccLedgers}&fromDate=${formData.fromDate}&toDate=${formData.toDate}`}
                                                target="_blank"
                                            >
                                                {company.companyName}
                                            </Link>
                                        </td>
                                        {/* <td className="text-end">{numberFormat(company.opBalance)}</td> */}
                                        <td className="text-end">{numberFormat(company.totalSalesOrder)}</td>
                                        <td className="text-end">{numberFormat(company.totalActualInvoicedAmount)}</td>
                                        <td className="text-end">{numberFormat(company.totalActualPaymentAmount)}</td>
                                        <td className="text-end">{numberFormat(company.totalInvoicedOtherAmount)}</td>
                                        <td className="text-end">{numberFormat(company.totalCollectionOtherAmount)}</td>
                                        <td className="text-end">{numberFormat(company.totalBalance)}</td>
                                        <td className="text-end">{numberFormat(company.totalShipmentAmount)}</td>
                                        <td>{company.salesRepresentatives}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={10} className="text-center">
                                        No Data Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={2} className="text-center">Total</td>
                                <td className="text-end">{numberFormat(salesData.reduce((a, b) => a + Number(b.totalSalesOrder), 0))}</td>
                                <td className="text-end">{numberFormat(salesData.reduce((a, b) => a + Number(b.totalActualInvoicedAmount), 0))}</td>
                                <td className="text-end">{numberFormat(salesData.reduce((a, b) => a + Number(b.totalActualPaymentAmount), 0))}</td>
                                <td className="text-end">{numberFormat(salesData.reduce((a, b) => a + Number(b.totalInvoicedOtherAmount), 0))}</td>
                                <td className="text-end">{numberFormat(salesData.reduce((a, b) => a + Number(b.totalCollectionOtherAmount), 0))}</td>
                                <td className="text-end">{numberFormat(salesData.reduce((a, b) => a + Number(b.totalBalance), 0))}</td>
                                <td className="text-end">{numberFormat(salesData.reduce((a, b) => a + Number(b.totalShipmentAmount), 0))}</td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </Table>
                </div>
            </div>
        </>
    );
};

export default SalesReport;
