import { Button, Col, Row, Table } from "react-bootstrap-v5";
import { getRequestOptions, postRequestOptions } from "../helpers/Fetchwrapper";
import { useEffect, useState } from "react";
import Select from "react-select";
import showLoader from "../helpers/ShowLoader";
import Swal from "sweetalert2";
import React from 'react';
import {numberFormat } from '../helpers/GlobalHelper';  

interface LedgerData {
    netProfitBeforeTax: number;
    inventory: number;
    accountsReceivable: number;
    interBuCurrentAccount: number;
    advances: number;
    vatCurrentAccount: number;
    tradePayable: number;
    ibuInterestPayable: number;
    otherPayable: number;
    ncfo: number;
    ncfi: number;
    shortTermLoan: number;
    bankOverdraft: number;
    ncff: number;
    ncf: number;
    openingBalance: number;
    closingBalance: number;
}

export default function CashOverflowStatement() {

    // Helper function to format a Date object as yyyy-MM-dd
    const formatDate = (date: Date) => {
        // return date.toISOString().split("T")[0];
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero
        const day = date.getDate().toString().padStart(2, "0"); // Add leading zero
        return `${year}-${month}-${day}`;
    };

    // Get the current date
    const today = new Date();

    // Determine the fiscal start and end dates
    const fiscalYearStart = today.getMonth() < 6 // Before July
    ? new Date(today.getFullYear() - 1, 6, 1) // Previous year July 1st
    : new Date(today.getFullYear(), 6, 1); // Current year July 1st

    const fiscalYearEnd = new Date(fiscalYearStart.getFullYear() + 1, 5, 30); // Next year June 30th

    // Default start and end dates formatted as yyyy-MM-dd
    const defaultStartDate = formatDate(fiscalYearStart);
    const defaultEndDate = formatDate(fiscalYearEnd);

    const [cashOverflowData, setCashOverflow] = useState<LedgerData>({} as LedgerData);
    const [selectProjectOptions, setSelectProjectOptions] = useState([])
    const [selectedProject, setSelectedProject] = useState<{ value: string; label: string; } | null>(null);
    const [startDate, setStartDate] = useState<string | null>(defaultStartDate);
    const [endDate, setEndDate] = useState<string | null>(defaultEndDate);

    useEffect(() => {
        // Check if selectProjectOptions is available before proceeding
        if (selectProjectOptions.length === 0) return;
        setSelectedProject(selectProjectOptions[0]);

    }, [selectProjectOptions]);

    useEffect(() => {
        geBalanceSheet();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject, startDate, endDate]);
    
    async function getProjectList() {
        const url = process.env.REACT_APP_API_URL + 'projects';

        const response = await fetch(url, getRequestOptions());

        const data = await response.json();

        data.data.map((element: any) => {
            element.value = element.id_projects;
            element.label = element.project_name;
            return element;
        })
        setSelectProjectOptions(data.data);
        setSelectedProject(selectProjectOptions[0]);
    }

    const handleProjectChange = (project: any) => {
        setSelectedProject(project);
    };

    async function geBalanceSheet() {
        showLoader();
        const response = await fetch(process.env.REACT_APP_API_URL + 'reports/cash-overflow-statement', postRequestOptions(
            {
                idProjects: selectedProject?.value,
                startDate: startDate,
                endDate: endDate
            }
        ))
        const data = await response.json();
        if (data.success) {
            Swal.close();
            setCashOverflow(data.data);
        } else {
            Swal.close();
            // toast(data.errors, { type: 'error' });
        }
    }

    useEffect(() => {
        geBalanceSheet();
        getProjectList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const queryParams = new URLSearchParams([
        ['idProjects', selectedProject?.value || ''],
        ['startDate', startDate || ''],
        ['endDate', endDate || '']
    ]);

    const formatAmount = (amount) => {
        return amount < 0 ? `(${numberFormat(Math.abs(amount))})` : numberFormat(amount);
    };
    

    return <div className='card card-custom'>
        <div className='card-header'>
            <div className="card-title">
                <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                    Cash Overflow Statement
                </span>
            </div>
            <div className='card-toolbar'>
                <div className='d-flex justify-content-end align-items-center'>
                    <Select
                        value={selectedProject}
                        className="form-control-sm h-50px w-250px me-3"
                        defaultValue={selectProjectOptions}
                        onChange={handleProjectChange}
                        options={selectProjectOptions}
                        placeholder="Select project"
                    />
                    <Row>
                        <Col className="d-flex align-items-center text-end pe-0">
                            From:
                        </Col>
                        <Col>
                            <input onChange={e => { setStartDate(e.target.value) }} value={startDate || ""} type="date" className="d-inline form-control form-control-sm" />
                        </Col>
                        <Col className="d-flex align-items-center text-end pe-0">
                            To:
                        </Col>
                        <Col>
                            <input onChange={e => { setEndDate(e.target.value) }} value={endDate || ""} type="date" className="d-inline form-control form-control-sm" />
                        </Col>
                    </Row>
                    <Button onClick={() => geBalanceSheet()} variant="primary" className="ms-2 p-2 me-2" size="sm">Refresh</Button>
                    <a className="btn btn-sm btn-primary p-2 ml-2" href={`${process.env.REACT_APP_BACKEND}cash_overflow_statement_download?` + queryParams.toString()}><i className='fas fa-download'></i>Excel Download</a>
                    {/* <pre>{JSON.stringify(defaultStartDate, null, 2)}</pre>
                    <pre>{JSON.stringify(defaultEndDate, null, 2)}</pre>
                    <pre>{JSON.stringify(startDate, null, 2)}</pre> */}
                </div>
            </div>
        </div>
        <div className='card-body'>
            <div className="row">
                <div className="col">
                    <div className="trial-balance-container table-responsive">
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th className="p-2 border text-center" width="50%">Particulars</th>
                                    <th className="p-2 border text-center" width="50%">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="p-2 border">Net profit before tax</td>
                                    <td className="p-2 border text-end">
                                        {formatAmount(cashOverflowData.netProfitBeforeTax)}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}></td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Decrease /(Increase) of Inventory</td>
                                    <td className="p-2 border text-end">{formatAmount(cashOverflowData.inventory)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Decrease /(Increase) of receivable</td>
                                    <td className="p-2 border text-end">{formatAmount(cashOverflowData.accountsReceivable)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Decrease/(Increase) of Inter company receivable</td>
                                    <td className="p-2 border text-end">{formatAmount(cashOverflowData.interBuCurrentAccount)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">(Increase)/decrease of advance</td>
                                    <td className="p-2 border text-end">{formatAmount(cashOverflowData.advances)}</td>
                                </tr>

                                <tr>
                                    <td className="p-2 border">(Increase) /decrease of VAT CA</td>
                                    <td className="p-2 border text-end">{ formatAmount(cashOverflowData.vatCurrentAccount)}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}></td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Increase/(decrease) of Trade payable</td>
                                    <td className="p-2 border text-end">{ formatAmount(cashOverflowData.tradePayable)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Increase/(decrease) of Intercompany payable </td>
                                    <td className="p-2 border text-end">{formatAmount(cashOverflowData.ibuInterestPayable)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Increase/(decrease) of Other payable</td>
                                    <td className="p-2 border text-end">{formatAmount(cashOverflowData.otherPayable)}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}></td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Net cash inflow from operating activity(A)</td>
                                    <td className="p-2 border text-end">{formatAmount(cashOverflowData.ncfo)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Investing activities:(B)</td>
                                    <td className="p-2 border text-end">{formatAmount(cashOverflowData.ncfi)}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}></td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Borrowing from bank</td>
                                    <td className="p-2 border text-end">{formatAmount(cashOverflowData.shortTermLoan)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Reduce OD balance</td>
                                    <td className="p-2 border text-end">{formatAmount(cashOverflowData.bankOverdraft)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Net cash flow from financing activity: (C)</td>
                                    <td className="p-2 border text-end">{formatAmount(cashOverflowData.ncff)}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}></td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Net cash inflow( A+B+C)</td>
                                    <td className="p-2 border text-end">{formatAmount(cashOverflowData.ncf)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border"><strong>Opening balance</strong></td>
                                    <td className="p-2 border text-end"><strong>{formatAmount(cashOverflowData.openingBalance)}</strong></td>
                                </tr>
                                <tr>
                                    <td className="p-2 border"><strong>Closing balance</strong></td>
                                    <td className="p-2 border text-end"><strong>{formatAmount(cashOverflowData.closingBalance)}</strong></td>
                                </tr>
                               
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            
        </div>
    </div >
}